<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  import Gen from '@helper/Gen';
  import draggable from 'vuedraggable'

  export default {
    name: "BoNews",
    extends: Base,
    components: {
      draggable
    },
    data() {
      return {
        Name: "BoNews",
        hero: {},
        status: {},
        webSettings: {},
        titleLength: 0,
        metatitleLength: 0,
        maxmetatitleLength: 60,
        descLength: 0,
        metadescLength: 0,
        maxmetadescLength: 160,
        data:[]
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
    },
    methods: {
      endDrag() {
        BOGen.apirest('/' + this.Name, {
          data: this.data.data,
          type: 'sort'
        }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
      sort() {
        this.filter.sort = this.filter.sort == 'all' ? '' : 'all'
        if (this.filter.sort == 'all') {
          this.filter.status = ''
          this.filter.statusProduct = ''
        }
        var query = this.filter
        delete query.page
        this.$router.push({
          name: this.Name,
          query: query
        })
      },
      submitView() {
        BOGen.apirest('/' + this.Name, {
          data: this.webSettings,
          type: 'updateView'
        }, (err, resp) => {
          if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.info')
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, '.info')
            this.refreshData()
          }
        }, "POST");
      },
    },
    watch: {
      '$route.query'(v) {
        if (this.$route.params.id) {
          this.$set(this.row, 'status', this)
          this.$set(this.row, 'search', this)
          this.$set(this.row, 'page', this)
          setTimeout(() => {
            this.row.status = this.$route.query.status
            this.row.search = this.$route.query.search
            this.row.page = this.$route.query.page
          }, 500)
        }
        this.refreshData()
      },
      'row.an_title'(v) {
        this.titleLength = v.length
        if(this.row.type === 'add'){
         this.row.an_slug = v.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
        }
      },
      'row.an_slug'(v){
        this.row.an_slug = v.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
      },
      'row.an_short_desc'(v) {
        this.descLength = v.length
      },
      'filter.status'(v) {
        this.search()
      },
      'filter.search'(v) {
        this.search()
      },
      'row.an_meta_title'(v){
         this.metatitleLength = v.length
      },
      'row.an_meta_desc'(v){
         this.metadescLength = v.length
      }
    }
  };
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>

    <div class="row">
      <div class="col-12" v-if="!$route.params.id">
        <div class="card mb-2">
          <div class="card-header">
            <h5 class="card-title">View Article by</h5>
          </div>
          <div class="card-body ">
            <div class="info"></div>
            <VForm @resp="submitView" class="row align-items-center">
              <div class="col-6">
                <BoField name="as_val" class="mb-0">
                  <radio name="as_val" v-model="webSettings.as_val" option="P" :attr="validation('as_val')">Publish Date
                  </radio>
                  <radio name="as_val" v-model="webSettings.as_val" option="S">Sorting</radio>
                </BoField>
              </div>
              <div class="col-6 text-right">
                <button type="submit" class="fcbtn btn btn-info btn-rounded btn-outline btn-1e btn-loading">Update <i
                    class="icon-arrow-right14 position-right"></i></button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="!$route.params.id">
        <div class="card">
          <div class="card-header">
            <VForm @resp="search">
              <div class="row align-items-center gutter-10">
                <div class="col-lg-4 col-sm-9 col-6">
                  <h5 class="card-title mb-0">News & Event List</h5>
                </div>
                <div class="col-lg-2 col-sm-3 col-6 text-right">
                  <button class="btn btn-success" type="button" @click="sort()">
                    <i class="fas fa-sort"></i> Show All
                  </button>
                </div>
                <div class="ml-sm-auto ml-lg-0 col-lg-2 col-sm-4">
                  <select2 :options="status" v-model="filter.status">
                    <option value="">-- Select Status --</option>
                  </select2>
                </div>
                <div class="col-9 col-lg-3 col-sm-4">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
                        placeholder="Search...">
                      <div class="input-group-append">
                        <button class="btn btn-info" type="button" @click="search()">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3 col-lg-1 col-sm-auto">
                  <router-link :to="{name:Name}" class="btn btn-block btn-warning">Reset</router-link>
                </div>
              </div>
            </VForm>
          </div>
          <div class="card-body">
            <div class="row" v-if="!$route.params.id">
              <div class="col-12">
                <draggable v-model="data.data" tag="div" class="row gutter-20" @end="endDrag">
                  <div class="col-lg-3 col-md-4" v-for="(v,k) in data.data" :key="k">
                    <div class="item-list collection">
                      <div class="row text-center">
                        <div class="col-md-12">
                          <div class="product-img recipe-cat mb-2">
                            <img :src="uploader(v.an_img,'250')" alt="prod">
                            <div class="pro-img-overlay">
                              <router-link class="bg-info" :to="{name:Name,params:{id:v.id},query:$route.query}"
                                v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
                              <a href="javascript:;" class="bg-danger" @click="deleteItem($event,k)"
                                v-tooltip="'Remove'"><i class="ti-trash"></i></a>
                            </div>
                            <span class="label label-info" v-if="v.an_is_active=='D'">Draft</span>
                            <span class="label label-success" v-if="v.an_is_active=='P'">Publish</span>
                            <span class="label label-warning" v-if="v.an_is_active=='A'">Archive</span>
                          </div>
                          <h5 class="card-title mt-1" v-tooltip="(v.an_title.length > 40 ? v.an_title:'')">{{(v.an_title.substr(0,40))+(v.an_title.length > 40 ? "...":'')}}</h5>
                          <small>{{(v.an_publish_date||'').dates('format')}}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
                <div v-if="NotFound" class="text-center col-md-12">
                  <h5 class="tc">{{NotFound}}</h5>
                </div>
                <div v-if="data.data===false" class="text-center col-md-12">
                  <LoadingSpinner light></LoadingSpinner>
                </div>
              </div>
            </div>
            <Pagination class="justify-content-end mt-3 mb-0" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
          </div>
        </div>
      </div>

      <div class="col-12" v-if="$route.params.id">
        <div class="card">
          <VForm @resp="submitForm" method="post">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-8">
                  <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
                </div>
              </div>
              <div class="info"></div>
              <div class="row">
                <div class="col-md-7">
                  <BoField class="mb-0" name="an_title" v-model="row.an_title"></BoField>
                  <small class="mb-2 d-inline-block text-info">{{validation('an_title').maxlength-titleLength}}
                    character remaining </small>                    
                  <BoField name="an_slug" v-model="row.an_slug"></BoField>
                  <BoField class="mb-0" name="an_short_desc" mandatory>
                    <textarea name="an_short_desc" v-model="row.an_short_desc" class="form-control"
                      v-bind="validation('an_short_desc')"></textarea>
                  </BoField>
                  <small class="mb-2 d-inline-block text-info">{{validation('an_short_desc').maxlength-descLength}}
                    character remaining </small>

                  <BoField name="an_desc" mandatory class="mb-0">
                    <CKEditor name="an_desc" v-model="row.an_desc" v-bind="validation('an_desc')"></CKEditor>
                  </BoField>
                  <BoField name="an_is_featured" class="mb-0">
                    <radio name="an_is_featured" v-model="row.an_is_featured" option="Y"
                      :attr="validation('an_is_featured')">Yes</radio>
                    <radio name="an_is_featured" v-model="row.an_is_featured" option="N">No</radio>
                    <div class="row">
                    </div>
                  </BoField>
                  <BoField name="an_is_active">
                    <radio name="an_is_active" v-model="row.an_is_active" option="D" :attr="validation('an_is_active')">
                      Draft</radio>
                    <radio name="an_is_active" v-model="row.an_is_active" option="P">Publish</radio>
                    <radio name="an_is_active" v-model="row.an_is_active" option="A">Archive</radio>
                  </BoField>
                </div>
                <div class="col-md-5">
                  <BoField name="an_img">
                    <Uploader name="an_img" type="news" uploadType="cropping" :param="{thumbnail:true}"
                      v-model="row.an_img"></Uploader>
                  </BoField>
                  <BoField name="an_author" v-model="row.an_author"></BoField>
                  <BoField name="an_tags" mandatory>
                    <TagsInput name="an_tags" v-model="row.an_tags" :attr="validation('an_tags')"
                      :placeholder="'Input tags'"></TagsInput>
                  </BoField>
                  <BoField name="an_publish_date">
                    <DatePicker name="an_publish_date" v-model="row.an_publish_date"
                      v-bind="validation('an_publish_date')"></DatePicker>
                  </BoField>
                  
                </div>
                <div class="col-12">
                  <hr>
                  <h5 class="card-title mb-3">Meta Content</h5>
                  <div class="row">
                    <div class="col-sm-6">
                      <BoField class="mb-0" name="an_meta_title" :attr="{minlength:'3'}" v-model="row.an_meta_title" mandatory></BoField>
                      <small class="mb-2 d-inline-block text-info">{{maxmetatitleLength-metatitleLength}} character remaining </small> 
                      <BoField name="an_meta_keyword" mandatory>
                        <TagsInput name="an_meta_keyword" v-model="row.an_meta_keyword"
                          :attr="validation('an_meta_keyword')" :placeholder="'Add tag then enter'"></TagsInput>
                      </BoField>
                    </div>
                    <div class="col-sm-6">
                      <BoField class="mb-0" name="an_meta_desc" mandatory>
                        <textarea name="an_meta_desc" rows="5" :attr="{minlength:'10'}" v-model="row.an_meta_desc" v-bind="validation('an_meta_desc')" class="form-control"></textarea>
                      </BoField>
                      <small class="mb-2 d-inline-block text-info">{{maxmetadescLength-metadescLength}} character remaining </small> 
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="text-right">
                    <button type="submit"
                      class="fcbtn btn btn-info btn-outline btn-1e btn-loading btn-rounded">{{(row.id ? "Update":"Add")+" News"}}
                      <i class="icon-arrow-right14 position-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </VForm>
        </div>
      </div>
    </div>
  </div>
</template>